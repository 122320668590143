import * as React from 'react';
import { Link } from 'gatsby';
import styled from '../theme';
import Container from '../components/sections/Container';
import Intro from '../components/sections/Intro';
import Layout from '../components/layout';

const StyledLink = styled(Link)`
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  font-style: italic;
  text-decoration: none;
  color: #1c1b20;
  background: none;
  border: none;
  padding-top: 40px;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    overflow: hidden;
    background: #1c1b20;
    z-index: 0;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <Container current="default">
      <Intro title="Not Found" subTitle="" />
      <StyledLink to="/">Get back home</StyledLink>
    </Container>
  </Layout>
);

export default NotFoundPage;
