import * as React from 'react';
import styled from '../../theme';
import { splitSentence } from '../../lib/utils';

const PaddingDiv = styled.div`
  padding-bottom: 0;
  padding-top: 30vh;
  padding-right: 40px;
  padding-left: 40px;
  position: relative;
  z-index: 10;
  padding-bottom: 60px;
  @media all and (min-width: 50em) {
    padding-right: 19%;
    padding-left: 50%;
    height: 100vh;
    padding-top: 50vh;
  }
`;

const PageTitle = styled.h1`
  line-height: 0.6;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
  font-size: 8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #1c1b20;
  margin-bottom: 60px;
  @media all and (min-width: 50em) {
    position: absolute;
    left: 20%;
    transform: translateY(-50%);
    margin-top: 0;
    top: 50vh;
    font-size: 13.6rem;
  }
`;

const Mask = styled.span`
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  line-height: 0.8;
  padding-top: 10px;
`;

const Content = styled.div`
  position: relative;
  top: 0;
  max-width: 280px;
  color: white;
  @media all and (min-width: 50em) {
    width: 600px;
    margin-left: 0;
    position: absolute;
    top: 50vh;
    transform: translateY(-53%);
    margin-top: 0;
    max-width: 100%;
  }
`;

const SubTitle = styled.p`
  margin-top: 105px;
  font-size: 2rem;
  margin-bottom: 0;
  color: #f8f8f8;
  font-style: normal;
  line-height: 1.2;
  @media all and (min-width: 50em) {
    font-size: 3.3rem;
  }
`;

const SubTitleContent = styled.span`
  display: inline-block;
  font-weight: 700;
  font-style: normal;
`;

interface Props {
  title: string;
  subTitle?: string;
}

const Intro: React.SFC<Props> = ({ subTitle = '', title }) => {
  const sub = splitSentence(subTitle);
  return (
    <section>
      <PaddingDiv>
        <PageTitle>
          {title.split(' ').map((t: string) => <Mask key={t}>{t}</Mask>)}
        </PageTitle>
        <SubTitle>
          {sub[0] && <SubTitleContent>{sub[0]}</SubTitleContent>}
          {sub[1] && (
            <SubTitleContent>
              <strong>{sub[1]}</strong>
            </SubTitleContent>
          )}
        </SubTitle>
      </PaddingDiv>
    </section>
  );
};

export default Intro;
